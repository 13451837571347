@import './_mixins';
@import './palette';

.mid-container,
body {
  background: $generalBackground no-repeat center center fixed; //var(--imgBg)
}

.mid-content {
  background-color: $generalBackground;
  padding-top: 30px;
  padding-bottom: 35px;

  .mid-sub-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    overflow-y: auto;
  }
}

.mid-center-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mid-content-box {
  background-color: $greyBackgroundColor;
  border-radius: 10px;
  border: 1px dotted $color04;
  overflow: hidden;
  padding: 3rem;
}

.cc-window {
  &.cc-banner {
    z-index: 10000000;
  }
}

.cdk-overlay-pane {
  .mat-dialog-container {
    color: $textColor;
    background-color: $generalBackground;
    // border-radius: 10px;
    // border: 1px solid $color05;
    // box-shadow: 0px 0px 30px 10px $greyBackgroundColor;
  }

  .mat-autocomplete-panel {
    background-color: $secondaryActionBackgroundColor;
  }
}

.cdk-overlay-container {
  z-index: 10029 !important;
}

.page-sub-header {
  top: 75px;
  z-index: 1021;

  .header {
    padding: 10px 20px 0 20px;

    .badge-readonly {
      position: relative;
      top: -3px;
      opacity: 0.5;
      cursor: default;
    }
  }
}

@media (max-width: $mobile) {
  .desktop-view-only {
    display: none;
  }
}
