@import '../palette';
@import '../variables';

.mid-intervention-container {
  position: relative;
  min-height: 180px;
  min-width: 240px;
  flex-basis: 240px;
  max-width: 240px;

  .mid-intervention-container-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: auto;
    // background-position: right -85px;
    background-image: url($imgUrl + 'mid-media.png');
    background-repeat: no-repeat;
    height: auto;
    opacity: 0.5;
  }

  .mid-intervention-content {
    position: relative;
    z-index: 1;
  }

  .mid-intervention-date {
    position: absolute;
    right: 3px;
    bottom: 3px;
    opacity: 0.8;
  }

  .mid-intervention-number {
    position: absolute;
    right: 3px;
    top: 3px;
    opacity: 0.8;
  }

  .mid-intervention-type {
    font-size: $baseFontSize;
  }
}
