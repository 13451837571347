html[data-theme='light'] {
  --generalBackground: #f7f7fb;
  --panelBackground: #edeef4;
  --secondaryActionBackgroundColor: #ffffff;
  --secondaryActionBackgroundColorHover: #acb5cf;
  --secondaryActionBorderColor: #d9dbe3;
  --secondaryActionLink: #8a8ea9;
  --shadow: #acb5cf;
  --textColor: #484b5d;
  --disableBackground: #3c3f4e;
}
