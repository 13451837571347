.mid-pagination {
  .mid-pagination-left,
  .mid-pagination-right {
    font-size: 16px;
    cursor: pointer;
    color: $color01;

    &:hover {
      color: $color03;
    }

    &.mid-active {
      color: $color04;
    }
  }
}
