@import '../palette';

.multi-select-dropdown-field {
  &.mat-form-field {
    width: 100%;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      display: none;
    }
  }

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background: $secondaryActionBackgroundColor;
      padding: 0 !important;
      border-radius: 2px;
    }

    .mat-form-field-infix {
      border: 1px solid transparent;
      padding: 6px 4px;
      border-radius: 2px;
    }
  }

  .multi-select-dropdown .mat-select-arrow,
  &.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: transparent;
  }
}

.multi-select {
  input.mat-input-element,
  .mat-select-value-text {
    color: $textColor;
  }
}

.mat-select-panel {
  background: $secondaryActionBackgroundColor;
}

.mat-option-text {
  color: $textColor;
}

.mat-select-placeholder {
  color: $disableTextColor;
}
