body {
  font-family: Roboto;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: Roboto;
  // font-style: normal;
  font-weight: 900;
  color: $textColor;
  // text-align: left;
}

h1 {
  font-size: 1.75rem; // 28px;
  line-height: 39px;
}

h2 {
  font-size: 1.563rem; // 25px;
  line-height: 39px;
}

h3 {
  font-size: 1.375rem; // 22px;
  line-height: 36px;
}

h4 {
  font-size: 1.25rem; // 20px;
  line-height: 27px;
}

h5 {
  font-size: 1.125rem; // 18px;
  line-height: 25px;
}

h6 {
  font-size: 1rem; // 16px;
  line-height: 18px;
}

.mid-text-primary,
.mid-link-primary {
  color: $primaryAction;
}

.mid-link-primary:hover {
  text-decoration: none;
}

.mid-link-primary {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
}

.mid-title-header {
  color: $color03;
  text-transform: uppercase;
}

.text-huge {
  font-size: $hugeFontSize;
}

.text-extra-large {
  font-size: $extraLargeFontSize;
}

.text-large {
  font-size: $largeFontSize;
}

.text-medium-small {
  font-size: $mediumSmallFontSize;
}

.text-medium {
  font-size: $mediumFontSize;
}

.text-medium-large {
  font-size: $mediumLargeFontSize;
}

.text-small {
  font-size: $smallFontSize;
}

.text-xs {
  font-size: $extraSmallFontSize;
}

.text-tiny {
  font-size: $tinyFontSize;
}

.text-transform-none {
  text-transform: none;
}

.text-break-all-words {
  word-break: break-all;
}

.mid-color-saved {
  color: $lightGreenColor;
}

.mid-color-error {
  color: $redColor;
}
