$color01: #666666;
$color02: #e0e0e0;
$color03: #fccd2d;
$color04: #dc972e;
$color05: #6d779f;
$color06: #454aa0;
$color07: #ef7b77;
$color08: #b5524e;
$color09: #30343e;
$color10: #3e4252;
$color11: #c3c3c3;
$color12: #757575;
$color13: #bbbbbb;
$color14: #3887be;
