.mid-menu {
  background: $panelBackground;
  color: $textColor;
  font-size: 14px;
  width: 290px;
  padding: 8px 3px;

  .mid-menu-info {
    padding: 0 3px 5px;
  }

  .mid-menu-item {
    background: $secondaryActionBackgroundColor;
    color: $primaryAction;
    padding: 9px 20px;
    border-radius: $cornerRadius;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
      background: $secondaryActionBackgroundColorHover;
    }
  }

  .mid-menu-item-icon {
    color: $secondaryActionLink;
    width: 20px;
  }

  .mid-menu-item-red {
    color: $infoRed;

    .red-icon {
      color: $infoRed;
    }
  }

  .mid-menu-item-fill-red {
    color: $textColor !important;
    background-color: $infoRed;

    &:hover {
      background: $infoRed !important;
      color: $textColor !important;
    }
  }
}

.mat-form-field.no-hint {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      bottom: 0;
    }
  }
}

.intervention-form {
  line-height: inherit;
  max-width: 460px;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-flex {
    background-color: $secondaryActionBackgroundColor;
    border-radius: 4px;
    padding: 5px;
  }

  .mat-form-field-wrapper .mat-form-field-flex {
    align-items: center;
  }

  .mat-input-element {
    caret-color: $textColor;
  }

  .mat-input-element,
  .mat-select-value-text {
    color: $textColor;
  }

  .mat-icon {
    width: 15px;
    height: 15px;
  }
}

.hint {
  .mat-hint {
    color: $primaryAction;
  }
}

.media-card {
  .mat-progress-bar-fill::after {
    border-radius: $cornerRadius;
  }

  .mat-progress-bar-element {
    background-color: $disableTextColor;
  }
}

.mat-tooltip {
  white-space: pre-line;
}
