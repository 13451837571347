$imgUrl: '/assets/images/';

$font_title: 'Comfortaa', sans-serif;
$font_title_weight_normal: 300;
$font_title_weight_bold: 600;
$font_text: 'Open Sans', sans-serif;
$font_text_weight_normal: 400;
$font_text_weight_bold: 600;

$headerHeight: 65px;
$footerHeight: 25px;
$mapPanelFooterHeight: 70px;
$mediaBoxHeight: 191px;
$midSimpleDashboardBlockTitleHeight: 35px;

$baseFontSize: 0.92rem;
$tinyFontSize: 0.55rem;
$extraSmallFontSize: 0.65rem;
$smallFontSize: 0.75rem;
$mediumSmallFontSize: 0.83rem;
$mediumFontSize: $baseFontSize;
$mediumLargeFontSize: 1rem;
$largeFontSize: 1.25rem;
$extraLargeFontSize: 1.5rem;
$hugeFontSize: 2.5rem;

$mobile: 576px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;
