.mid-card {
  background-color: $color01;
  border-radius: 10px;
  border: 1px solid $color04;

  -webkit-box-shadow: 0 0 30px 10px $greyBackgroundColor;
  -moz-box-shadow: 0 0 30px 10px $greyBackgroundColor;
  box-shadow: 0 0 30px 10px $greyBackgroundColor;

  .mid-card-logo {
    padding-top: 20px;
    padding-bottom: 15px;
  }
}
