html[data-theme='dark'] {
  --generalBackground: #383b47;
  --panelBackground: #484b5d;
  --secondaryActionBackgroundColor: #565a76;
  --secondaryActionBackgroundColorHover: #454a6d;
  --secondaryActionBorderColor: #6c77a3;
  --secondaryActionLink: #c8cce7;
  --shadow: #1c1e2440;
  --textColor: #ffffff;
  --disableBackground: #3c3f4e;
}
