@import 'src/assets/scss/mixins';

.mid-header-menu {
  .filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-filter {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .search-filter-buttons {
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }

  .search-section-btn {
    border: none;
    padding: 0.25rem 1rem;
    background: $secondaryActionBackgroundColor;
    color: $textColor;
    > * {
      font-weight: 600;
      white-space: nowrap;
    }

    &.active,
    &:hover {
      background: $textColor;
      color: $secondaryActionBackgroundColor;
    }
  }

  .search-input {
    background: $secondaryActionBackgroundColor;
    max-width: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    border-radius: 2px;
    padding: 0.2rem;

    input {
      max-width: 250px;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 2px;
    }

    .fas {
      color: $secondaryActionBorderColor;
    }
  }

  .search-filter-extra-btn {
    > * {
      color: $primaryAction;
      padding: 0.25rem 1.6rem;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 21px;
    }

    &:hover {
      background: $secondaryTransparent;
    }
  }

  @include media-breakpoint-down(md) {
    .search-filter,
    .filter-section {
      flex-direction: column;
      gap: 1rem;
    }
    .search-input {
      max-width: 100%;
      width: 100%;
      input {
        max-width: 96%;
        width: 100%;
      }
    }
    .selection-btn {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .filter-section {
      display: none;
    }
  }
}
