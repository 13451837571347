@import '../palette';

/* buttons */
.mid-btn-primary {
  background-color: $color04;
  color: $greyBackgroundColor;
  border-color: $color04;
  margin-bottom: 20px;
  outline: none;

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 5px 0 $color03;
    -moz-box-shadow: 0 0 5px 0 $color03;
    box-shadow: 0 0 5px 0 $color03;
  }

  &.active:not([disabled]),
  &:active:not([disabled]),
  &:hover:not([disabled]) {
    outline: none;
    background-color: $color03 !important;
    border-color: $color04 !important;
    -webkit-box-shadow: 0 0 5 0 $color03 !important;
    -moz-box-shadow: 0 0 5px 0 $color03 !important;
    box-shadow: 0 0 5px 0 $color03 !important;
  }

  &.mid-in-saving {
    background-color: $color03 !important;
    color: $greyBackgroundColor !important;
    border-color: darken($color03, 20%) !important;
  }

  &.mid-error {
    background-color: red !important;
    color: $textColor !important;
    border-color: darken(red, 20%) !important;
  }

  &.mid-saved {
    background-color: $lightGreenColor;
    border-color: darken($lightGreenColor, 20%);
  }
}

.btn.focus,
.btn:focus {
  &.mid-btn-icon {
    outline: 0;
    box-shadow: none;
  }
}
