@import 'src/assets/scss/palette';

.fas-icon {
  display: flex;
  color: $primaryAction;
  cursor: pointer;
  margin-top: -36px;
  padding: 9px 8px 11px 8px;
  background-color: $disableBackground;
}

.warning-input-text {
  color: $infoRed;
}

.mid-form {
  .mid-form-label {
    color: $color04;
  }

  .mid-input-box {
    position: relative;
  }

  .mid-select {
    .mid-loading,
    .mid-success {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .mid-fa-success,
    .mid-fa-loading {
      right: 25px;
    }
  }

  .mid-input {
    .mid-error,
    &.mid-error {
      border-color: $redColor;
      border-width: 2px;
      & ~ .mid-fa-error {
        display: block;
      }
      & ~ .mid-text-error {
        display: block;
      }
      & ~ .mid-validation-error {
        display: block;
      }
    }

    &.mid-success {
      border-color: green;
      border-width: 2px;
      & ~ .mid-fa-success {
        display: block;
      }
    }

    &.mid-loading {
      & ~ .mid-fa-loading {
        display: block;
      }
    }
  }

  .mid-fa-success,
  .mid-fa-error,
  .mid-fa-loading {
    position: absolute;
    top: 7px;
    right: 23px;
    color: $black;
    display: none;
  }

  .mid-fa-success,
  .mid-fa-success-color {
    color: green;
  }

  .mid-fa-error,
  .mid-fa-error-color {
    color: $redColor;
  }

  .mid-text-error {
    position: absolute;
    bottom: -12px;
    right: 15px;
    background-color: $redColor;
    font-size: 14px;
    padding: 0 10px;
    color: $textColor;
    border-radius: 0 0 0 5px;
    display: none;
  }

  .mid-validation-error {
    background-color: $redColor;
    font-size: 14px;
    padding: 0 10px;
    color: $textColor;
    border-radius: 0 0 0 5px;
    display: none;
  }

  .mid-fa-loading {
    color: $color01;
  }

  // .mid-btn-primary {
  //   background-color: $color05;
  //   color: $greyBackgroundColor;
  //   border-color: $color04;

  //   &:active,
  //   &.mid-in-saving {
  //     background-color: $color04 !important;
  //     color: $greyBackgroundColor !important;
  //     border-color: darken($color04, 20%) !important;
  //   }

  //   &.mid-error {
  //     background-color: red !important;
  //     color: white !important;
  //     border-color: darken(red, 20%) !important;
  //   }

  //   &.mid-saved {
  //     background-color: $lightGreenColor;
  //     border-color: darken($lightGreenColor, 20%);
  //   }
  // }
}

/* input */
.mid-input {
  position: relative;
  margin-bottom: 30px;

  .mid-form-control {
    background-color: $color01;
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 2px solid $color04;
    box-shadow: none;
    width: 100%;
    outline: none;
    color: $color03;
  }

  label {
    color: $color04;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    padding-left: 15px;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  .mid-form-control:focus ~ label,
  .mid-form-control:valid ~ label {
    top: -15px;
    font-size: 14px;
    color: $color03;
    padding-left: 15px;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $color03;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 15px;
  }
  .bar:after {
    right: 15px;
  }

  .mid-form-control:focus ~ .bar:before {
    width: 50%;
    left: 15px;
  }

  .mid-form-control:focus ~ .bar:after {
    width: 50%;
    right: 15px;
  }

  .mid-form-control:valid ~ .bar:before,
  .mid-form-control:valid ~ .bar:after {
    width: 50%;
    background: $color04;
  }

  &.last-child {
    margin-bottom: 15px;
  }
}

/** auto fill browser **/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  // border-bottom: 2px solid $color05;
  -webkit-text-fill-color: $textColor;
  transition: background-color 5000s ease-in-out 0s;

  // & ~ label {
  //   top: -15px;
  //   font-size: 14px;
  //   color: $color04;
  //   padding-left: 15px;
  // }
}

// Error form
.mid-box-error {
  color: $redColor;
  margin-bottom: 25px;
  height: 25px;
  .mid-text-error {
    display: none;
  }
}

.mid-form-error {
  .ng-invalid {
    border-color: $redColor;
    & ~ .bar::before,
    ~ .bar::after {
      display: none;
    }
  }
  .mid-box-error {
    .mid-text-error {
      display: block;
    }
  }
}
