// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'assets/scss/palette';
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-light-theme-foreground: (
  base: $black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $black,
  hint-text: $dark-disabled-text,
  secondary-text: $color04,
  icon: rgba($black, 0.54),
  icons: rgba($black, 0.54),
  text: rgba($black, 0.87),
  slider-min: rgba($black, 0.87),
  slider-off: rgba($black, 0.26),
  slider-off-active: rgba($black, 0.38),
);

.mat-form-field-wrapper {
  .mat-form-field-ripple {
    height: 1px !important;
    background-color: inherit;
  }
}

.mat-form-field-underline,
.mat-form-field-underline::before {
  background-color: $color04 !important;
}

/* Font color */
input.mat-input-element,
.mat-select-value-text {
  color: $color03;

  &:disabled,
  &.mat-form-field-disabled {
    color: rgba($color04, 0.5);
  }
}

button:focus {
  outline: 0;
}

$mat-custom-primary: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #dc972e,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: $greyBackgroundColor,
    100: $greyBackgroundColor,
    200: $greyBackgroundColor,
    300: $greyBackgroundColor,
    400: $greyBackgroundColor,
    500: $greyBackgroundColor,
    600: $greyBackgroundColor,
    700: $greyBackgroundColor,
    800: white,
    900: white,
    A100: $greyBackgroundColor,
    A200: $greyBackgroundColor,
    A400: $greyBackgroundColor,
    A700: black,
  ),
);

$midgard-ai-primary: mat.define-palette($mat-custom-primary);
$midgard-ai-accent: mat.define-palette($mat-custom-primary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$midgard-ai-warn: mat.define-palette(mat.$red-palette, A200);

// Create the theme object (a Sass map containing all of the palettes).
$midgard-ai-light-theme: mat.define-light-theme($midgard-ai-primary, $midgard-ai-accent, $midgard-ai-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($midgard-ai-light-theme);

@mixin mix-app-theme($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);

  .mat-button-toggle {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, default-contrast);
  }
}

// Include the mixin
@include mix-app-theme($midgard-ai-light-theme);

/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/variables';
@import 'assets/scss/index';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  // font-size: 16px; // default body style
  // line-height: 25px; // default body style
  color: $textColor;
  overflow: hidden;
}

.mid-content {
  > div {
    width: 100%;
  }
}

.mid-app-drone-stream-player {
  &.azuremediaplayer {
    height: auto;

    .vjs-tech {
      height: auto;
    }

    .vjs-player {
    }
  }

  &.mid-drone-stream-card-box {
    .vjs-playing .vjs-control-bar {
      bottom: 29px;
    }
  }

  &.mid-drone-stream-preview-box {
    .vjs-playing .vjs-control-bar {
      bottom: 0;
    }
  }

  &.mid-drones-preview-box {
    .vjs-playing .vjs-control-bar {
      bottom: 0;
    }
  }
}

.mid-fix-bottom-controllers {
  .vjs-control-bar {
    position: fixed;
    bottom: 0;
  }
}

.mid-show-controllers {
  .vjs-control-bar {
    opacity: 1 !important;
    z-index: 1000 !important;
  }
}

.mid-drone-single-box {
}

@media (min-width: 414px) {
  .mid-app-drone-stream-player {
    &.mid-drone-stream-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 22px;
      }
    }
  }
}

@media (min-width: $mobile) {
  .mid-app-drone-stream-player {
    &.mid-drone-stream-card-box {
      .vjs-playing .vjs-control-bar {
        bottom: 29px;
      }
    }

    &.mid-drone-stream-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 34px;
      }
    }

    &.mid-drones-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }
  }
}

@media (min-width: $tablet) {
  .mid-app-drone-stream-player {
    &.mid-drone-stream-card-box {
      .vjs-playing .vjs-control-bar {
        bottom: 29px;
      }
    }

    &.mid-drone-stream-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 34px;
      }
    }

    &.mid-drones-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }
  }
}

@media (min-width: $laptop) {
  .mid-app-drone-stream-player {
    &.mid-drone-stream-card-box {
      .vjs-playing .vjs-control-bar {
        bottom: -20px;
      }
    }

    &.mid-drone-stream-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }

    &.mid-drones-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }
  }
}

@media (min-width: $desktop) {
  .mid-app-drone-stream-player {
    &.mid-drone-stream-card-box {
      .vjs-playing .vjs-control-bar {
        bottom: -1px;
      }
    }

    &.mid-drone-stream-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }

    &.mid-drones-preview-box {
      .vjs-playing .vjs-control-bar {
        bottom: 0;
      }
    }
  }
}

/* notification component */

/* override cdk-global-overlay-wrapper */
.cdk-overlay-container .cdk-global-overlay-wrapper {
  padding-top: $headerHeight;
  padding-bottom: $footerHeight;
}

.mid-notification-component {
  background-color: transparent;
  padding: 0 !important;
  min-height: auto !important;
}

/* notification menu */
.mid-notification-panel {
  background-color: $color01;
  border-left: 1px solid $color04;
  border-right: 1px solid $color04;
  border-bottom: 1px solid $color04;
  border-radius: 0 0 5px 5px !important;
  padding-top: 8px !important;
  min-width: 240px !important;
}

.mid-notification-item-panel {
  background-color: darken($color01, 10%);
  color: $color02;
  //max-width: 200px;
  margin-bottom: 10px;
  position: relative;
  padding: 8px 25px;
  font-size: 14px;

  &:hover {
    background-color: $secondaryActionBorderColor; // darken($color02, 20%);
    z-index: 10;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.mid-notification-item-trash {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 10px;
  cursor: pointer;
}

@media (min-width: 414px) {
  .mid-notification-item-panel {
    max-width: none !important;
  }
}

.mid-notification-standard {
  background-color: $color01;
  color: $color03;
  padding: 10px 10px 8px 10px;
  border-radius: $cornerRadius;
  border: 1px solid $color04;
  position: relative;
  min-height: 45px;
}

// activity-stream classes
.mid-activity-stream-drone-live-title {
  color: $textColor;
}

.mid-activity-stream-new-media-title,
.mid-notification-item-progress-title {
  font-size: 16px;
  color: $textColor;
  line-height: 20px;
  word-break: break-all;
}

.mid-activity-media-import-progress {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mid-activity-stream-simple-text-content,
.mid-activity-stream-drone-live-content {
  font-size: 12px;
}

.mid-activity-stream-new-media {
  font-size: 14px;
  color: $textColor;
}

.mid-notification-item-progress {
  background-color: $secondaryActionBorderColor;
}

.mid-notification-item-progress-size {
  color: lighten($color01, 25%);
  font-size: 12px;
}

.mid-progress-bar-content {
  background-color: $greyBackgroundColor;
  border-radius: 0.25rem;
}

.mid-progress-bar {
  background-color: $color04;
}

.text-in-lines {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.text-in-lines:before,
.text-in-lines:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.text-in-lines:before {
  margin-right: 20px;
}

.text-in-lines:after {
  margin-left: 20px;
}

.text-with-border {
  font-weight: 600;
  border-left: solid 5px;
  padding-left: 10px;
  height: 25px;
}

.mid-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 18px;
}

.mid-simple-input {
  border-radius: 5px;
  border: 1px solid darkgray;
}

@mixin scrollbars($size, $foreground-color) {
  // Google Chrome
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $size;
    height: $size;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: gray;
    background-color: $foreground-color;
  }
  ::-webkit-scrollbar-track {
    background: $generalBackground;
  }
  // Firefox
  html {
    scrollbar-color: $secondaryActionBackgroundColor $generalBackground;
    scrollbar-width: none !important;
  }
}

@include scrollbars(7px, $secondaryActionBackgroundColor);
