@import 'dark/palette';
@import 'light/palette';
@import 'numbered-colors';

$primary: #f0a33f;

/*** Application Background Color ***/
$generalBackground: var(--generalBackground);

/*** Panel Background ***/
$panelBackground: var(--panelBackground);

/*** Primary Action ***/
/* Background Color */
$primaryAction: #fca016;
$primaryActionHover: #d08514;

/*** Secondary Action ***/
$secondaryActionBackgroundColor: var(--secondaryActionBackgroundColor);
$secondaryActionBackgroundColorHover: var(--secondaryActionBackgroundColorHover);
$secondaryActionBorderColor: var(--secondaryActionBorderColor);
$secondaryActionLink: var(--secondaryActionLink);
$secondaryTransparent: #565a787d;

/*** shadow ***/
$cornerRadius: 3px;
$shadow: var(--shadow);
$shadowRadius: 13px;
$shadowHover: #1c1e2480;
$shadowHoverRadius: 20px;

/*** Text Color ***/
$black: #000000;
$textColor: var(--textColor);
$redColor: #ff5252;
$greenColor: #19bb3e;
$lightGreenColor: #adff2f;
$darkGreyColor: #6c757d;

/*** Background Color ***/
$greyBackgroundColor: #3d3d3d;

/*** Disable Color ***/
$disableBackground: var(--disableBackground);
$disableTextColor: #999ca9;

/*** Badge and Infos Colors ***/
/* Badge and Infos Colors/Red (delete, attention) */
$infoRed: #ff7373;
/* Badge and Infos Colors/Green (validation, in progress) */
$infoGreen: #2fa7a7;
/* Badge and Infos Colors/Warning Info Text */
$infoWarning: #f0c88c;
/* Infos Colors/White (state informative, optional) */
$infoWhite: #ffffff;
/* Badge and Infos Colors/Default Badge */
$infoDefault: #a5875b;
/* Badge and Infos Colors/Closed */
$infoClosed: #868eaf;

$greyAlert: #c4c4c4;

$inputBorder: #dadada;
