.mid-media-header {
  background-color: rgba($greyBackgroundColor, 0.8);
  color: $color04;
  border-bottom: 1px solid $color04;
  font-size: 14px;
}

.mid-media-container-heigth {
  min-height: $mediaBoxHeight;
}

.mid-media-container-max-heigth {
  max-height: $mediaBoxHeight;
}

.mid-media-container-notification {
  min-height: 23px !important;
}

.mid-snapshot,
.mid-video {
  background-color: rgba($greyBackgroundColor, 0.8);
  background-position: bottom right;
  background-image: url($imgUrl + 'mid-placeholder-media.png');
  background-repeat: no-repeat;
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
  border: 1px solid $color04;
  overflow: hidden;
  min-height: $mediaBoxHeight;

  .mid-snapshot-label,
  .mid-video-label {
    position: absolute;
    width: 100%;
    text-align: center;
    color: $color04;
    background-color: $greyBackgroundColor;
    border-bottom: 1px solid $greyBackgroundColor;
    font-size: 12px;
    padding: 2px 0;
  }

  .mid-snapshot-label-remove,
  .mid-video-label-remove {
    position: absolute;
    right: 5px;
    bottom: 0;
  }
}

// drone-stream
.mid-drone-stream,
.mid-snapshot,
.mid-share-stream {
  background-color: $black;
  padding-top: 0;
  padding-bottom: 0;
}

.mid-media-container {
  cursor: pointer;
  -webkit-transition-duration: 0.5s; /* Safari & Chrome */
  transition-duration: 0.5s;
  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
  }
}
